<template>
  <app-modal
    v-model="model"
    :submitting="status === 'pending'"
    title="Add a new document"
    @submit="execute"
  >
    <app-form-input-text
      v-model="form.title"
      data-cy-title
      label="Title"
      required
    />

    <app-form-select
      v-model="choice"
      data-cy-visibility
      item-title="label"
      item-value="id"
      :items="choices"
      :return-object="false"
    >
      <template #item="{ item, props }">
        <v-list-item
          :prepend-icon="item.icon"
          v-bind="props"
          :subtitle="item.description"
          :title="item.label"
          :value="item.id"
        />
      </template>
    </app-form-select>

    <app-form-input-file
      v-if="choice === 'internal'"
      v-model="file"
      :accept="['application/pdf']"
      required
    />

    <app-form-input-text
      v-if="choice === 'external'"
      v-model="form.url"
      data-cy-url
      label="URL"
      required
      :rules="{ url: true }"
    />
  </app-modal>
</template>

<script lang="ts" setup>
import { useFileUpload } from "~/core/storage/composables/file-upload.hook";

const model = defineModel({ default: false });

const properties = defineProps<{
  course_session_id?: DatabaseTable<"course_sessions">["id"];
  course_id?: DatabaseTable<"courses">["id"];
}>();

const form = reactive<{
  course_session_id?: (typeof properties)["course_session_id"];
  course_id?: (typeof properties)["course_id"];
  title: string;
  url: string;
  bucket?: string;
}>({
  title: "",
  url: "",
  course_session_id: properties.course_session_id,
  course_id: properties.course_id,
});

const choices = [
  {
    id: "external",
    label: "External",
    description:
      "You want to attach a publically available document. All you need is a public URL.",
    icon: "ph:link-simple",
  },
  {
    id: "internal",
    label: "Private",
    description:
      "Upload a private document that will be stored securely. Usefull for course slides.",
    icon: "ph:lock-key",
  },
] as const satisfies {
  id: string;
  label: string;
  description: string;
  icon: string;
}[];

const choice = ref<(typeof choices)[number]["id"]>("external");

const file = ref<File | undefined>();

watch(choice, () => {
  file.value = undefined;
  form.url = "";
  form.bucket = undefined;
});

const { upload } = useFileUpload();

function getInternalFilePath() {
  if (properties.course_id) return `courses/${properties.course_id}/documents`;
  if (properties.course_session_id)
    return `sessions/${properties.course_session_id}/documents`;

  throw new Error("No course or session provided");
}

const { execute, status, error } = useAsyncData(
  "document_edition",
  async () => {
    if (choice.value === "internal" && file.value) {
      const response = await upload(file.value, {
        path: getInternalFilePath(),
        bucket: "private",
      });

      form.url = response.path;
      form.bucket = "private";
    }

    await $fetch("/api/documents", {
      method: "POST",
      body: form,
    });

    return useModal("documentEditionDialog").close({ confirmed: true });
  },
  { immediate: false },
);

watch(error, (errorValue) => {
  if (!errorValue?.statusMessage) return;

  useAlert().showError(errorValue.statusMessage);
});
</script>
